<template>
  <modal-container
    @cancel="$emit('cancel')"
  >
    <p class="text-xl font-bold text-left">
      Appointment Time
    </p>
    <p class="pt-2 text-left">
      Enter multiple user's email addresss and they will be
      invited to make an account with Kept In View!
    </p>

  </modal-container>
</template>

<script>
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';

export default {
  name: 'InviteUserModal',
  components: {
    ModalContainer,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
