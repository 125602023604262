<template>
  <label
    class="flex flex-col w-full my-2 dropdown-input"
  >
    <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
    <div
      class="flex justify-between px-2 mx-2 rounded-lg bg-background"
      :class="[
        !valid ? 'border-2 border-red-500' : 'border-2 border-transparent',
      ]"
    >
      <select
        @change="$emit('update:input', $event.target.value)"
        :value="input"
        :name="label"
        class="`
          flex-grow py-1 font-bold focus:outline-none bg-transparent
          min-w-0 text-content
        `"
      >
        <option v-for="item in items" :key="item.date" :value="item.date">{{item.time}}</option>
      </select>
    </div>
  </label>
</template>

<script>
export default {
  name: 'AssistanceBookingTimePicker',
  props: {
    label: {
      type: String,
      required: true,
    },
    input: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    valid: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    if (this.default) this.$emit('update:input', this.default.date);
  },
};
</script>
