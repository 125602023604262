<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :loading="loading"
      :modal-show="modal.showAssistanceDatePicker || modal.showAssistanceTimePicker"
    >

      <template #modal>
        <assistance-booking-date-picker-modal
          v-if="modal.showAssistanceDatePicker"
          @cancel="modal.showAssistanceDatePicker = false"
          :date.sync="datePickerValue"
          :busyDates="disabledDates()"
        />

        <assistance-booking-time-picker-modal
          v-if="modal.showAssistanceTimePicker"
          @cancel="modal.showAssistanceTimePicker = false"
        />
      </template>

      <template #title>
        Schedule Consultation
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <dropdown-component
              label="Select a Topic"
              :value.sync='appointmentInfo.title'
              :items="titleOptions"
              mode="form"
            />

            <textarea-component
              :value.sync="appointmentInfo.description"
              label="Description"
              :valid="true"
              mode="form"
              :rows="5"
            />

            <div
              @click="modal.showAssistanceDatePicker = true"
              class="text-left"
            >
              <p class="px-3 text-lg font-extrabold text-left text-primary">Select a Date</p>
              <div
                class="`
                  flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none
                  min-w-0 bg-background mx-2
                `"
              >
                <p class="cursor-pointer text-content ">{{formateDate(appointmentInfo.date)}}</p>
              </div>
            </div>

            <assistance-booking-time-picker
              label="Select an Appointment Time"
              :input.sync="appointmentInfo.date"
              :items="availableTimeSlots"
              :default="availableTimeSlots[0]"
              :valid="true"
              class="mb-4"
            />

          </div>

          <div class="flex flex-row justify-around mp-5">
            <button-large
              @click="cancel"
              class="w-1/3"
              color="red"
            >
              Cancel
            </button-large>

            <button-large
              @click="book"
              class="w-1/3"
              color="green"
            >
              Book
            </button-large>
          </div>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>
/* eslint-disable no-continue */

import moment from 'moment';

import { EVENT_POST, EVENTS_GLOBAL_GET } from '@/store/actions';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import AssistanceBookingTimePicker from '@/components/assistance/AssistanceBookingTimePicker.vue';
import AssistanceBookingDatePickerModal from '@/components/assistance/AssistanceBookingDatePickerModal.vue';
import AssistanceBookingTimePickerModal from '@/components/assistance/AssistanceBookingTimePickerModal.vue';
import DropdownComponent from '@/components/shared/inputs/DropdownComponent.vue';
import TextareaComponent from '@/components/shared/inputs/TextareaComponent.vue';
import ButtonLarge from '../../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'AssistanceBooking',
  components: {
    InteriorPageContentContainer,
    AssistanceBookingDatePickerModal,
    AssistanceBookingTimePickerModal,
    AssistanceBookingTimePicker,
    DropdownComponent,
    TextareaComponent,
    ButtonLarge,
  },
  data() {
    return {
      loading: true,
      modal: {
        showAssistanceDatePicker: false,
        showAssistanceTimePicker: false,
      },
      appointmentInfo: {
        title: '',
        description: '',
        date: moment(new Date()).toISOString(),
        durationMin: 30,
      },
      titleOptions: [
        'Home downsizing consultation',
        'Home decluttering consultation',
        'Home safety consultation',
        'App support',
        'Business relation',
      ],
      numOfAppointments: 16,
      daysAhead: 28,
      datePickerValue: null,
      bookedTimeSlots: [],
      availableAppointments: {},
    };
  },
  created() {
    this.appointmentInfo = {
      ...this.appointmentInfo,
      title: this.titleOptions[0],
    };

    // Starter code to prevent double booking
    this.$store.dispatch(EVENTS_GLOBAL_GET)
      .then((busyInfo) => {
        const { busy } = busyInfo.calendars[Object.keys(busyInfo.calendars)[0]];
        const sortedBusyDates = this.sortBusyDates(busy);

        const date = moment(new Date()).subtract(1, 'days');
        for (let i = 0; i < this.daysAhead; i += 1) {
          const curDate = date.add(1, 'days').format('ddd MMM DD, YYYY');
          this.availableAppointments[curDate] = [];
          const startOfWorkDay = date.startOf('day').add(9, 'hours').startOf('hour');
          for (let j = 0; j < this.numOfAppointments; j += 1) {
            const currAppointment = moment(new Date(startOfWorkDay.toISOString()));
            if (this.validAppointment(sortedBusyDates, currAppointment)) {
              this.availableAppointments[curDate].push({
                date: currAppointment.toISOString(),
                time: this.formatTime(currAppointment),
                chosen: false,
              });
            }

            startOfWorkDay.add(this.appointmentInfo.durationMin, 'minutes');
          }

          // No available dates for the current date so the date get's blocked
          if (this.availableAppointments[curDate].length === 0) {
            this.bookedTimeSlots.push(new Date(date.toISOString()));
          } else if (!this.datePickerValue) {
            this.datePickerValue = new Date(date.toISOString());
          }
        }
      })
      .finally(() => { this.loading = false; });
  },
  computed: {
    availableTimeSlots() {
      return this.availableAppointments[this.formateDate(this.datePickerValue)];
    },
  },
  watch: {
    datePickerValue() { this.appointmentInfo.date = this.availableTimeSlots[0].date; },
  },
  methods: {
    validAppointment(sortedBusyDates, appointment) {
      const currAppointment = moment(appointment).add(1, 'minute');
      const dayNum = moment(appointment).get('day');
      if (currAppointment.isBefore(moment(new Date()))) return false;
      if (dayNum === 6 || dayNum === 0) return false;
      if (sortedBusyDates[this.formateDate(appointment)]) {
        let isBooked = false;
        sortedBusyDates[this.formateDate(appointment)].forEach((busyTime) => {
          isBooked = !isBooked
            ? currAppointment.isBetween(busyTime.start, busyTime.end)
            : isBooked;
        });
        return !isBooked;
      }

      return true;
    },
    sortBusyDates(busyDates) {
      const busyDatesSorted = {};
      busyDates.forEach((busyDate) => {
        const curDate = this.formateDate(busyDate.start);
        if (!busyDatesSorted[curDate]) busyDatesSorted[curDate] = [];
        busyDatesSorted[curDate].push(busyDate);
      });
      return busyDatesSorted;
    },
    disabledDates() {
      const curDate = new Date();

      const beforeBlock = {
        start: null,
        end: curDate.setDate((curDate).getDate() - 1),
      };
      const weekendBlock = { weekdays: [1, 7] };
      const afterTwoWeekBlock = {
        start: curDate.setDate((curDate).getDate() + this.daysAhead),
        end: null,
      };
      return [
        beforeBlock,
        weekendBlock,
        afterTwoWeekBlock,
        ...this.bookedTimeSlots,
      ];
    },
    formateDate(date) {
      return moment(
        date,
      ).format('ddd MMM DD, YYYY');
    },
    formatTime(momentVal) {
      const start = momentVal.format('h:mm A');
      const end = momentVal.add(this.appointmentInfo.durationMin, 'm').format('h:mm A');
      return `${start} - ${end}`;
    },
    book() {
      this.loading = true;
      this.$store.dispatch(EVENT_POST, {
        title: this.appointmentInfo.title,
        description: this.appointmentInfo.description,
        start: this.appointmentInfo.date,
        end: moment(this.appointmentInfo.date).add(this.appointmentInfo.durationMin, 'minutes').toISOString(),
      })
        .finally(() => {
          this.loading = false;
          this.$router.push({
            name: 'Assistance',
            params: { tab: 'appointment' },
          });
        });
    },
    cancel() {
      this.$router.push({
        name: 'Assistance',
        params: { tab: 'appointment' },
      });
    },
    backNav() {
      this.$router.go(-1);
    },

  },
};
</script>
